import { isNullOrUndefined } from '@app/_helpers/utils';
import { format, toDate } from 'date-fns-tz/esm';
import { listTimeZones, findTimeZone } from 'timezone-support';
export const ALL_TIMEZONES_GENERIC: { [key: string]: string } = {
  'Pacific/Midway': 'Midway Island, Samoa',
  'Pacific/Honolulu': 'Hawaii',
  'America/Juneau': 'Alaska',
  'America/Boise': 'Mountain Time',
  'America/Dawson': 'Dawson, Yukon',
  'America/Chihuahua': 'Chihuahua, La Paz, Mazatlan',
  'America/Phoenix': 'Arizona',
  'America/Chicago': 'Central Time',
  'America/Regina': 'Saskatchewan',
  'America/Mexico_City': 'Guadalajara, Mexico City, Monterrey',
  'America/Belize': 'Central America',
  'America/Detroit': 'Eastern Time',
  'America/Bogota': 'Bogota, Lima, Quito',
  'America/Caracas': 'Caracas, La Paz',
  'America/Santiago': 'Santiago',
  'America/St_Johns': 'Newfoundland and Labrador',
  'America/Sao_Paulo': 'Brasilia',
  'America/Tijuana': 'Tijuana',
  'America/Montevideo': 'Montevideo',
  'America/Argentina/Buenos_Aires': 'Buenos Aires, Georgetown',
  'America/Godthab': 'Greenland',
  'America/Los_Angeles': 'Pacific Time',
  'Atlantic/Azores': 'Azores',
  'Atlantic/Cape_Verde': 'Cape Verde Islands',
  GMT: 'UTC',
  'Europe/London': 'Edinburgh, London',
  'Europe/Dublin': 'Dublin',
  'Europe/Lisbon': 'Lisbon',
  'Africa/Casablanca': 'Casablanca, Monrovia',
  'Atlantic/Canary': 'Canary Islands',
  'Europe/Belgrade': 'Belgrade, Bratislava, Budapest, Ljubljana, Prague',
  'Europe/Sarajevo': 'Sarajevo, Skopje, Warsaw, Zagreb',
  'Europe/Brussels': 'Brussels, Copenhagen, Madrid, Paris',
  'Europe/Amsterdam': 'Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
  'Africa/Algiers': 'West Central Africa',
  'Europe/Bucharest': 'Bucharest',
  'Africa/Cairo': 'Cairo',
  'Europe/Helsinki': 'Helsinki, Kiev, Riga, Sofia, Tallinn, Vilnius',
  'Europe/Athens': 'Athens, Minsk',
  'Asia/Jerusalem': 'Jerusalem',
  'Africa/Harare': 'Harare, Pretoria',
  'Europe/Moscow': 'Istanbul, Moscow, St. Petersburg, Volgograd',
  'Asia/Kuwait': 'Kuwait, Riyadh',
  'Africa/Nairobi': 'Nairobi',
  'Asia/Baghdad': 'Baghdad',
  'Asia/Tehran': 'Tehran',
  'Asia/Dubai': 'Abu Dhabi, Muscat',
  'Asia/Baku': 'Baku, Tbilisi, Yerevan',
  'Asia/Kabul': 'Kabul',
  'Asia/Yekaterinburg': 'Ekaterinburg',
  'Asia/Karachi': 'Islamabad, Karachi, Tashkent',
  'Asia/Kolkata': 'Chennai, Kolkata, Mumbai, New Delhi',
  'Asia/Kathmandu': 'Kathmandu',
  'Asia/Dhaka': 'Astana, Dhaka',
  'Asia/Colombo': 'Sri Jayawardenepura',
  'Asia/Almaty': 'Almaty, Novosibirsk',
  'Asia/Rangoon': 'Yangon Rangoon',
  'Asia/Bangkok': 'Bangkok, Hanoi, Jakarta',
  'Asia/Krasnoyarsk': 'Krasnoyarsk',
  'Asia/Shanghai': 'Beijing, Chongqing, Hong Kong SAR, Urumqi',
  'Asia/Kuala_Lumpur': 'Kuala Lumpur, Singapore',
  'Asia/Taipei': 'Taipei',
  'Australia/Perth': 'Perth',
  'Asia/Irkutsk': 'Irkutsk, Ulaanbaatar',
  'Asia/Seoul': 'Seoul',
  'Asia/Tokyo': 'Osaka, Sapporo, Tokyo',
  'Asia/Yakutsk': 'Yakutsk',
  'Australia/Darwin': 'Darwin',
  'Australia/Adelaide': 'Adelaide',
  'Australia/Sydney': 'Canberra, Melbourne, Sydney',
  'Australia/Brisbane': 'Brisbane',
  'Australia/Hobart': 'Hobart',
  'Asia/Vladivostok': 'Vladivostok',
  'Pacific/Guam': 'Guam, Port Moresby',
  'Asia/Magadan': 'Magadan, Solomon Islands, New Caledonia',
  'Asia/Kamchatka': 'Kamchatka, Marshall Islands',
  'Pacific/Fiji': 'Fiji Islands',
  'Pacific/Auckland': 'Auckland, Wellington',
  'Pacific/Tongatapu': "Nuku'alofa",
};
const options: { value: string; label: string; offset: number; zones: string[]; area: string }[] = [];
Object.entries(ALL_TIMEZONES_GENERIC)
  .filter(([x]) => {
    const zone = x.split('/', 2);
    return zone.length > 1 && zone[0] !== 'Etc';
  })
  .reduce((obj: { name: string; label: string; offset: number; zones: string[]; area: string }[], [zone, name]) => {
    const timezoneOffset = (((format(new Date(), 'XX', { timeZone: zone }) as any) ^ 0) / 100) * 60;
    const label = name.replace('_', '');
    obj.push({
      name: zone,
      area: zone.split('/', 2)?.[0],
      label,
      offset: timezoneOffset,
      zones: label.split(',').map((x) => x.trim()),
    });
    return obj;
  }, [])
  .sort((a, b) => {
    return a.offset - b.offset;
  })
  .map((tz) => {
    if (isNullOrUndefined(tz.offset)) return null;
    return {
      value: tz.name,
      label: tz.label,
      offset: tz.offset,
      zones: tz.zones,
      area: tz.area,
    };
  })
  .filter((x) => !!x)
  .forEach((x: any) => options.push(x));
export function getBrowserTimezone() {
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [area, zone] = tz.split('/', 2);
  return (
    options.find(
      (x) =>
        x.area.toLowerCase() === area.toLowerCase() &&
        x.zones.findIndex((z) => z.toLowerCase() === zone.toLowerCase()) !== -1
    )?.value ?? tz
  );
}
export function findTimezoneByName(name: string) {
  const [area, zone] = name.split('/', 2);
  return options.find(
    (x) =>
      x.area.toLowerCase() === area.toLowerCase() &&
      x.zones.findIndex((z) => z.toLowerCase() === zone.toLowerCase()) !== -1
  );
}
export default options;
